<!-- ChmlFrp | 随机移动粒子连线特效 -->
<template>
    <div class="particle-lines-background">
        <canvas ref="canvas"></canvas>
    </div>
</template>
  
<script>
export default {
    mounted() {
        // 获取 canvas 元素和上下文
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");

        // 设置 canvas 大小为窗口大小
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        // 创建粒子数组
        const particles = [];
        const numParticles = 70;

        // 创建粒子对象
        for (let i = 0; i < numParticles; i++) {
            particles.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                vx: Math.random() * 2 - 1, // 随机水平速度
                vy: Math.random() * 2 - 1, // 随机垂直速度
                radius: Math.random() * 2 + 1, // 粒子半径较小
                color: `rgba(10, 10, 10, 0.5)`,
            });
        }

        // 动画循环
        const animate = () => {
            // 清空画布
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // 更新粒子位置并绘制
            particles.forEach((particle, index) => {
                particle.x += particle.vx;
                particle.y += particle.vy;

                // 使粒子在画布内循环
                if (particle.x < 0) particle.x = canvas.width;
                if (particle.x > canvas.width) particle.x = 0;
                if (particle.y < 0) particle.y = canvas.height;
                if (particle.y > canvas.height) particle.y = 0;

                // 绘制粒子
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
                ctx.fillStyle = particle.color;
                ctx.fill();

                // 连接粒子之间的线
                for (let j = index + 1; j < particles.length; j++) {
                    const distance = Math.sqrt(
                        (particles[j].x - particle.x) ** 2 + (particles[j].y - particle.y) ** 2
                    );
                    if (distance < 100) {
                        ctx.beginPath();
                        ctx.moveTo(particle.x, particle.y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                        ctx.strokeStyle = "rgba(0, 0, 0, 0.3)";
                        ctx.lineWidth = 1;
                        ctx.stroke();
                    }
                }
            });

            // 递归调用动画循环
            requestAnimationFrame(animate);
        };

        // 启动动画循环
        animate();
    },
};
</script>
  
<style scoped>
.particle-lines-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
}
</style>  