<template>
    <div style="background-color: rgba(255, 255, 255); height: 100vh;">
        <div class="center-content">
            <Lizi />
            <el-avatar :size="100" src="https://chmlfrp.cn/favicon.ico"></el-avatar>
            <h1>Prompt page</h1>
            <p>如果您忘记了邮箱或邮箱已废弃或想重置QQ号，可以联系QQ：242247494进行更改</p>
        </div>
    </div>
</template>

<script>
import Lizi from './lizi/Lianxian.vue'; // 请根据你的项目结构调整路径

export default {
    components: {
        Lizi,
    },
};
</script>

<style lang="less">
.center-content {
    display: flex;
    flex-direction: column;
    /* 垂直居中时，垂直排列子元素 */
    justify-content: center;
    /* 垂直居中 */
    align-items: center;
    /* 水平居中 */
    height: 70%;
    /* 使其占据整个父元素的高度 */
}

.custom-form {
    width: 100%;
    max-width: 360px;
    /* 设置最大宽度为360px */
}
</style>